import React, { useContext, useState } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Text,
    FormLabel,
    useToast,
} from "@chakra-ui/react"

import { UserContext } from "~config/user-context"
import { updateRoom, getUserByEmail, createNewNotification } from "~queries"
import { InsertNotification, UpdateRoom, Room } from "~models"

const InviteUsersModal: React.FC<{
    isOpen: boolean
    onClose: () => void
    room: Room
}> = ({ isOpen, onClose, room }) => {
    const toast = useToast()
    const { user } = useContext(UserContext)

    const [email, setEmail] = useState("")

    const submit = async () => {
        const { status: emailStatus, data: foundUser } = await getUserByEmail(
            email
        )
        if (emailStatus === "error" || !foundUser) {
            toast({
                title: "No user with that email.",
                status: "error",
            })
            return
        }

        const notif: InsertNotification = {
            type: "invite",
            message: `${user?.username} (${user?.email}) has invited you to join room '${room.id}'.  You can join this room by clicking the link.`,
            link: `https://valoqueue.com/${encodeURI(room.id)}`,
        }
        const { status: notifStatus } = await createNewNotification(
            foundUser.id,
            notif
        )

        const updateRoomData: UpdateRoom = {
            invitees: [...room.invitees, foundUser.id],
        }
        const { status: updateStatus } = await updateRoom(
            room.id,
            updateRoomData
        )
        if (updateStatus === "success" && notifStatus === "success") {
            toast({
                title: "Users invited.",
                description: `Their invite can be found in the Notifications tab in their Account page.`,
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast({
                title: "Failed to invite users.",
                status: "error",
            })
        }

        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p="20px">
                <ModalHeader>Invite Users to Room</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Invite other users to join your room, or use the link below.
                    Anyone with this link can join.
                    <Text>{`https://valoqueue.com/${encodeURI(room.id)}`}</Text>
                </ModalBody>
                <FormLabel>Invite by email</FormLabel>
                <Input
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                />

                <ModalFooter>
                    <Button colorScheme="teal" mr={3} onClick={submit}>
                        Invite
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default InviteUsersModal
