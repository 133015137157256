import React, { useState } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Checkbox,
    FormLabel,
    useToast,
    Spinner,
} from "@chakra-ui/react"

import { updateRoom } from "~queries"
import { UpdateRoom, Room } from "~models"

const EditRoomModal: React.FC<{
    isOpen: boolean
    onClose: () => void
    editRoom: Room
}> = ({ isOpen, onClose, editRoom }) => {
    const toast = useToast()
    const [description, setDescription] = useState(editRoom.description)
    const [discordLink, setDiscordLink] = useState(editRoom.discordLink)
    const [isPrivate, setIsPrivate] = useState(editRoom.private)

    const [isUpdating, setIsUpdating] = useState(false)

    const submit = async () => {
        const room: UpdateRoom = {
            description,
            discordLink,
            private: isPrivate,
        }
        setIsUpdating(true)
        const result = await updateRoom(editRoom.id, room)
        if (result.status === "success") {
            toast({
                title: "Room updated.",
                description: `We've successfully updated room ID: ${editRoom.id}.`,
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast({
                title: "Room update failed.",
                description: result.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
        onClose()
        setIsUpdating(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p="20px">
                <ModalHeader>Update Room</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Change features and settings of your room here.
                </ModalBody>
                <FormLabel>Room ID</FormLabel>
                <Input type="text" value={editRoom.id} disabled />

                <FormLabel>Description</FormLabel>
                <Input
                    type="text"
                    value={description}
                    onChange={event => setDescription(event.target.value)}
                />

                <FormLabel>Discord Link</FormLabel>
                <Input
                    type="text"
                    value={discordLink}
                    onChange={event => setDiscordLink(event.target.value)}
                />

                <FormLabel>Private Room</FormLabel>
                <Checkbox
                    isChecked={isPrivate}
                    onChange={event => setIsPrivate(event.target.checked)}
                />

                <ModalFooter>
                    <Button colorScheme="teal" mr={3} onClick={submit}>
                        {isUpdating ? <Spinner /> : "Update"}
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default EditRoomModal
